<template>
  <v-container fluid>
    <!-- start of form -->
    <editor
        :seriesData="seriesArray"
        :branchesData="branchData"
        :usersData="allUsersData"
        :deparmentData="departmentsArray"
        :defaultSeries="defaultSeries"
        :DefaultBPLId="DefaultBPLId"
        :docCreate="docCreate"
        :docCopyTo="docCopyTo"
        :setup="setup"
        :tableRows="tableRows"
        :initial="dataItem"
        :tableData="tableData"
        :objectName="objectName"
        :activeObject="activeObject"
        :withPayment="withPayment"
        @data="save"
        @postData="postData"
    ></editor>
    <!-- end of form -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_components/create-editor.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters({
      user: "user",
      getDocumentFormSettings: "documentFormSetting/getDocumentFormSettings",
      getDocumentModel: "getDocumentModel",
      localSettings:"getLocalSettings"
    }),
    branchData() {
      return this.$store.state.branches;
    },
    loggedInUser() {
      return this.$store.state.user;
    },
    allUsersData() {
      return this.$store.state.userdata;
    },
  },
  data: () => ({
    dataItem: { parent: false, document_lines: [] },
    docCreate: true,
    docCopyTo: false,
    hasAttachments: false,
    tenantData: {},
    editMode: false,
    currencies: [],
    setup: {},
    defaultSeries: {},
    seriesArray: [],
    departmentsArray: [],
    usersArray: [],
    saleDocs: [],
    objectName: null,
    record: {},
    tableData: [],
    tableRows: [],
    DefaultBPLId: null,
    activeObject: null,
    withPayment: null,
    // allUsersData:null,
    options: {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  }),
  watch: {
    "$route.params.objectId": {
      handler: "formSettings",
      immediate: true,
    },
  },
  methods: {
    save(data) {
      const self = this;
      data.ObjType = this.activeObject;
      data.serviceCallId = this.$route.query.serviceCallId;

      const formData = new FormData();
      const files = data.oats || [];

      if (files.length > 0) {
        self.hasAttachments = true;
        files.forEach((file, f) => {
          const m_file = file.attachmedDetails;
          formData.append("files[" + f + "]", m_file);
        });
      }
        const url = "/documents"
        this.$store
            .dispatch("post", { url, data })
            .then((res) => {
              if (res.ResultCode == 1200) {
                if (self.hasAttachments == true){
                  self.$refs.snackbar.show("Uploading Documents", "green");
                  formData.append("ObjType", res.ResponseData.newObjType);
                  formData.append("DocEntry", res.ResponseData.id);
                  // this.$store.commit("loader", false);
                  this.uploadAttachments(formData,res.ResponseData);
                }
                this.$store.commit("loader", false);
                self.$router.push(`/sales/doc/${this.activeObject}`);
              } else {
                this.$store.commit("loader", false);
                self.$refs.snackbar.show(res.ResultDesc, "red");
              }
            })
            .catch((err) => {
              this.$store.commit("loader", false);
              self.$refs.snackbar.show(err, "red");
            })
    },

    postData(data) {
      const self = this;
      data.ObjType = this.activeObject;
      data.serviceCallId = this.$route.query.serviceCallId;
      const files = data.oats || [];
      if (files.length > 0) {
        console.log("files")
        const formData = new FormData();
          files.forEach((file, f) => {
            const m_file = file.attachmedDetails;
            formData.append("files[" + f + "]", m_file);
          });
        self.postToSap(data,formData)
      }else{
        self.postToSap(data)
      }
    },

    uploadAttachments(formData,data) {
      console.log(data)
      const url = "/attachments";
      // const url = "/attachments";
      const self = this;

      this.$store
          .dispatch("post", { url, data: formData })
          .then((res) => {
            if (res.ResultCode == 1200) {
              self.hasAttachments = false;
            } else {
              self.$refs.snackbar.show(res.ResultDesc, "red");
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },

    postToSap(data,formData = null){
      const self = this;
      const localUrl = self.localSettings.localurl;
      axios
          .post(
              localUrl+"/documents",
              data,
              this.options
          )
          .then((resp) => {
            if (resp.data.ResultCode == 1200) {
              // check for attachments and upload them
              if (formData){
                // prepare atttachment payload data
              formData.append("ExtRefisDraft",resp.data.ResponseData.ExtRefisDraft);
              formData.append("ExtRef",resp.data.ResponseData.ExtRef);
              formData.append("ExtRefDocNum",resp.data.ResponseData.ExtRefDocNum);
              formData.append("objType",resp.data.ResponseData.ObjType);
              formData.append("isDraft",resp.data.ResponseData.isDraft);
              formData.append("id",resp.data.ResponseData.id);
              formData.append("docNum",resp.data.ResponseData.DocNum);
                axios.put(
                          localUrl+"/attachments",
                              formData,
                              this.options
                          )
                          .then((resp) => {
                            console.log(resp)
                          }).catch((err)=>{
                        this.$store.commit("loader", false);
                        self.$refs.snackbar.show(err, "red");
                      });
              }
              //tims data and fiscalization
              if (this.activeObject == 13 && resp.data.ResponseData.newObjType != 112){
                axios
                    .post(
                        self.loggedInUser.oadm.TimsUrl,
                        resp.data.ResponseData,
                        this.options
                    )
                    .then((timsResp) => {
                      console.log(timsResp);
                    });
              }
            }
            self.$store.commit("loader", false);
            self.$router.push(`/sales/doc/${this.activeObject}?`);
          }).catch((err)=>{
        this.$store.commit("loader", false);
        if (err.response) {
          console.log(err.response.data)
          console.log("error")
          console.log(err.response.data.message);
          self.$refs.snackbar.show(err.response.data.ResultDesc, "red");
        } else {
          self.$refs.snackbar.show(err.message, "red");
        }
      });
    },

    updateTransferredStatus(ObjType,DocEntry,status){
      const self = this;
      const url = `sales_doc_update/${ObjType}/${DocEntry}?isDoc=0&status=${status}`;
      this.$store
          .dispatch("post", {url})
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            self.updateTransferredStatus(ObjType,DocEntry,"N");
            console.log(err);
          });
    },

    saveDraft(data) {
      const url = "/drafts";
      const self = this;
      this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            console.log(res, "r");
            // self.$refs.snackbar.show("Item group created", "green");
            self.$router.push("/sales/ar-invoice");
          })
          .catch((err) => {
            console.log(err, "err");
            //  console.log(err);
          });
    },

    formSettings(objectId) {
      localStorage.removeItem("UseShpdGd");
      this.activeObject = objectId;
      const self = this;
      this.loading = true;
      if (self.getDocumentFormSettings[objectId]){
        const documentFormSetting = self.getDocumentFormSettings[objectId];
        self.setup = documentFormSetting;
        self.tableRows = documentFormSetting.tabs[0].tableRows;
        self.seriesArray = documentFormSetting.Series;
        self.defaultSeries = documentFormSetting.DfltSeries;
        self.usersArray = documentFormSetting.users;

        self.departmentsArray = documentFormSetting.departments;
        self.DefaultBPLId = documentFormSetting.DefaultBPLId;
        self.loading = false;
      }else {
        this.$store
            .dispatch("get", `/form_settings/${objectId}`)
            .then((res) => {
              var newDocumentFormSetting = { ...this.getDocumentFormSetting };
              newDocumentFormSetting[objectId] = res;
              this.$store.commit("documentFormSetting/loadData", newDocumentFormSetting);
              self.setup = res;
              self.tableRows = res.tabs[0].tableRows;
              self.defaultSeries = res.DfltSeries;
              self.seriesArray = res.Series;
              self.usersArray = res.users;
              self.departmentsArray = res.departments;
              self.DefaultBPLId = res.DefaultBPLId;
              self.loading = false;
            })
            .catch((err) => {
              console.log(err, "error");
            });
      }

      // set object Name
      if(self.getDocumentModel[objectId]){
        self.objectName = self.getDocumentModel[objectId].DocumentName;
      }
      else {
      this.$store
          .dispatch("get", `/doc_model/${objectId}`)
          .then((res) => {
            var newDocumentModel = { ...this.getDocumentModel };
            newDocumentModel[objectId] = res;
            this.$store.commit("setDocumentModel", newDocumentModel);
            self.objectName = res.DocumentName;
          })
          .catch((err) => {
            console.log(err);
          });}
      // end

      if (this.activeObject !== 13) {
        this.withPayment = "N";
      }
      if (this.activeObject == 13) {
        this.withPayment = "Y";
      }
    },

    getUserData() {
      // const self = this;
      // this.loading = true;
      this.$store
          .dispatch("get", `/users`)
          .then((res) => {
            console.log(res)
            // self.allUsersData = res.ResponseData;
            // self.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
          .dispatch("get", `/currency`)
          .then((res) => {
            self.currencies = res;
            self.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    getData() {
      const self = this;
      this.$store
          .dispatch("get", `/branches`)
          .then((res) => {
            if (res.ResultCode == 1200) {
              self.userDefaults = res.ResponseData;
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },

  },

  created() {
    localStorage.removeItem("docType");
    localStorage.setItem("docType", "I");
    this.$store.commit("loader", false);
    // this.getData();
    // this.getUserData();
  },
};
</script>